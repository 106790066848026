@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import '../../../bower_components/font-awesome/scss/font-awesome';
@import url("http://fonts.googleapis.com/css?family=Source+Sans+Pro:300,900");

	@import "libs/skel";

	@include skel-breakpoints((
		wide: '(max-width: 1680px)',
		normal: '(max-width: 1280px)',
		mobile: '(max-width: 736px)',
		mobilep: '(max-width: 480px)'
	));

	@include skel-layout((
		reset: 'full',
		boxModel: 'border'
	));

	@mixin bg($width) {
		@include keyframes('bg') {
			0%		{ @include vendor('transform', 'translate3d(0,0,0)'); }
			100%	{ @include vendor('transform', 'translate3d(#{$width * -1},0,0)'); }
		}

		#bg {
			background-size: $width auto;
			width: ($width * 3);
		}
	}

	$delay-wrapper:			_duration(wrapper) - 1s;
	$delay-overlay:			$delay-wrapper - 0.5s;
	$delay-header:			$delay-overlay + _duration(overlay) - 0.75s;
	$delay-nav-icons:		$delay-header + _duration(header) - 1s;
	$delay-nav-icon:		0.25s;
	$delay-content:			$delay-nav-icons + _duration(nav-icons) - 1s;
	$delay-articles:		0.25s;

/* Basic */

	body, input, select, textarea {
		color: _palette(fg);
		font-family: 'Source Sans Pro', sans-serif;
		font-size: 15pt;
		font-weight: 300 !important;
		letter-spacing: -0.025em;
		line-height: 1.75em;
	}

	body {
		background: _palette(bg);
		overflow: hidden;

		&.loading{
			* {
				@include vendor('animation', 'none !important');
			}
		}
	}

	a {
		@include vendor('transition', 'border-color 0.2s ease-in-out');
		border-bottom: dotted 1px;
		color: inherit;
		outline: 0;
		text-decoration: none;

		&:hover {
			border-color: transparent;
		}
	}

	h1 {
		font-size: 4.35em;
		font-weight: 900;
		letter-spacing: -0.035em;
		line-height: 1em;
	}

	h1 {
		font-size: 2.35em;
		font-weight: 600;
		letter-spacing: -0.035em;
		line-height: 1em;
	}

/* Icon */

	.icon {
		@include icon;
		position: relative;

		> .label {
			display: none;
		}
	}

/* Circle Icon Wrapper */
	.circle-icon-list {
		text-align: center;

		li {
			@include vendor('animation', 'nav-icons #{_duration(nav-icons)} ease-in-out forwards');
			@include vendor('backface-visibility', 'hidden');
			@include vendor('transform', 'translate3d(0,0,0)');
			display: inline-block;
			height: _size(nav-icon-wrapper);
			line-height: _size(nav-icon-wrapper) * 1.1;
			opacity: 0;
			position: relative;
			top: 0;
			width: _size(nav-icon-wrapper);

			@for $x from 1 through 10 {
				&:nth-child(#{$x}) {
					@include vendor('animation-delay', ($delay-nav-icons + ($x * $delay-nav-icon)) + '');
				}
			}
		}

		a {
			-webkit-tap-highlight-color: rgba(0,0,0,0);
			-webkit-touch-callout: none;
			border: 0;
			display: inline-block;

			&:before {
				@include vendor('transition', 'all 0.2s ease-in-out');
				border-radius: 100%;
				border: solid 1px _palette(fg);
				display: block;
				font-size: _size(nav-icon);
				height: 2.5em;
				line-height: 2.5em;
				position: relative;
				text-align: center;
				top: 0;
				width: 2.5em;
			}

			&:hover {

				&:before {
					border: solid 1px _palette(nav-icon, hover-bg);
					background-color: _palette(nav-icon, hover-bg);
					color: _palette(nav-icon, hover-fg);
				}
			}

			&:active {
				font-size: 0.95em;
				background: none;

				&:before {
					background-color: _palette(nav-icon, active-bg);
					color: _palette(nav-icon, active-fg);
				}
			}

			span {
				display: none;
			}
		}
	}

/* Wrapper */

	@include keyframes('wrapper') {
		0%		{ opacity: 0; }
		100%	{ opacity: 1; }
	}

	#wrapper {
		@include vendor('animation', 'wrapper #{_duration(wrapper)} forwards');
		height: 100%;
		left: 0;
		opacity: 0;
		position: relative;
		top: 0;
		width: 100%;
	}

/* BG */

	#bg {
		@include vendor('animation', 'bg #{_duration(bg)} linear infinite');
		@include vendor('backface-visibility', 'hidden');
		@include vendor('transform', 'translate3d(0,0,0)');

		/* Set your background with this */
		background: _misc(bg);

		background-repeat: repeat-x;
		height: 100%;
		left: 0;
		opacity: 1;
		position: fixed;
		top: 0;
	}

	@include bg(_misc(bg-width) * 1.5);

/* Overlay */

	@include keyframes('overlay') {
		0%		{ opacity: 0; }
		100%	{ opacity: 1; }
	}

	#overlay {
		@include vendor('animation', 'overlay #{_duration(overlay)} #{$delay-overlay} forwards');
		background-attachment: fixed, fixed;
		background-image: url('../images/overlay-pattern.png'), url('../images/overlay.svg');
		background-position: top left, center center;
		background-repeat: repeat, no-repeat;
		background-size: auto, cover;
		height: 100%;
		left: 0;
		opacity: 0;
		position: fixed;
		top: 0;
		width: 100%;
	}

/* Main */

	#main {
		height: 100%;
		left: 0;
		position: absolute;
		text-align: center;
		top: 0;
		width: 100%;

		&:before {
			content: '';
			display: inline-block;
			margin-right: 0;
			vertical-align: middle;
			width: 1px;
		}
	}

/* Links */

	@include keyframes('header') {
		0%		{ @include vendor('transform', 'translate3d(0,1em,0)'); opacity: 0; }
		100%	{ @include vendor('transform', 'translate3d(0,0,0)'); opacity: 1; }
	}

	@include keyframes('nav-icons') {
		0%		{ @include vendor('transform', 'translate3d(0,1em,0)'); opacity: 0; }
		100%	{ @include vendor('transform', 'translate3d(0,0,0)'); opacity: 1; }
	}

	.page-links {
		cursor: default;
		opacity: 0;
		position: relative;
		text-align: center;
		top: 3em;
    	height: 35em;
		vertical-align: middle;

		p {
			font-size: 1.25em;
			margin: 0.75em 0 0.25em 0;
			opacity: 0.75;
		}

		nav {
			margin: 1.5em 0 0 0;
		}
	}

/* Content */
  @include keyframes('content') {
    0%		{ @include vendor('transform', 'translate3d(0,1em,0)'); opacity: 0; }
    100%	{ @include vendor('transform', 'translate3d(0,0,0)'); opacity: 1; }
  }

  @include keyframes('articles') {
    0%		{ @include vendor('transform', 'translate3d(0,1em,0)'); opacity: 0; }
    100%	{ @include vendor('transform', 'translate3d(0,0,0)'); opacity: 1; }
  }

	$content-text-margin: 0.5em;

	.page-experience, .page-education, .page-interests {
	    cursor: default;
	    opacity: 0;
	    text-align: center;
	    top: 1em;
	    vertical-align: middle;
	    width: 90%;
	    text-align: left;
	}

/* Process Bar */
	.progressbar {
	    background-color: _palette(bg);
	    border: 2px solid _palette(mg);
	}

	.progressbar > div {
	    background-color: _palette(mg);
	    height: 20px;

	    &.html {
	      width: 90%;
	    }
	    &.javascript {
	      width: 85%;
	    }
	    &.php {
	      width: 70%;
	    }
	    &.css {
	      width: 90%;
	    }
	    &.cli {
	      width: 85%;
	    }
	}

/* Footer */

	#footer {
		@include vendor('background-image', 'linear-gradient(top, rgba(0,0,0,0), rgba(0,0,0,0.5) 75%)');
    	color: _palette(mg);
		bottom: 0;
		cursor: default;
		height: 6em;
		left: 0;
		line-height: 8em;
		text-align: center;
		position: fixed;
		width: 100%;
	}

/* Tabs */
	.tabs {
		position: relative;
		margin: 0.1em auto;
		width: 736px;

		input {
		    position: absolute;
		    z-index: 1000;
		    left: 0px;
		    top: 0px;
		    opacity: 0;
		    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
		    filter: alpha(opacity = 0);
		    cursor: pointer;

			&.tab-selector-1:checked ~ .content .page-links, &.tab-selector-2:checked ~ .content .page-experience, &.tab-selector-3:checked ~ .content .page-education, &.tab-selector-4:checked ~ .content .page-interests {
			    z-index: 100;
			    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
			    filter: alpha(opacity = 100);
			    opacity: 1;
				@include vendor('backface-visibility', 'hidden');
				@include vendor('transform', 'translate3d(0,0,0)');
			}
		}

		input:checked + label {
			color: _pallete(mg);
			z-index: 6;
			@include vendor('animation', 'page 0.2s linear');
		}

		.tab-label-1, .tab-label-2, .tab-label-3, .tab-label-4 {
			margin: 0 1em;
			border-bottom: dotted 1px;
			color: inherit;
			outline: 0;
			text-decoration: none;

			&:hover {
				border-color: transparent;
			}
		}

		.tab-label-2 {
			z-index: 3;
		}

		.tab-label-3 {
			z-index: 2;
		}

		.tab-label-4 {
			z-index: 1;
		}

		.content {
			position: relative;
			width: 100%;
			height: 66em;
			z-index: 5;
			overflow: hidden;
			border-radius: 0 3px 3px 3px;

			div {
			    width: 100%;
			    position: absolute;
			    top: 0;
			    padding: 10px 40px;
			    z-index: 1;
			    opacity: 0;
				@include vendor('transition', 'all 1s ease-in-out');
			}

			h2, h3 {
				color: #398080;
				text-transform: uppercase;
			}

		    .page-links, .page-education, .page-experience, .page-interests {
				@include vendor('transform', 'translateX(-850px)');
			}
		}
	}

	@keyframes "page" {
		0% {
	    	left: 0;
		}

		50% {
	    	left: 10px;
		}

		100% {
	    	left: 0;
		}
	}

/* Wide */

	@include breakpoint(wide) {

		/* Basic */

			body, input, select, textarea {
				font-size: 13pt;
			}

		/* BG */

			@include bg(_misc(bg-width));

	}

/* Normal */

	@include breakpoint(normal) {

		/* Basic */

			body, input, select, textarea {
				font-size: 12pt;
			}

		/* BG */

			@include bg(_misc(bg-width) * 0.5);

			.tabs {
				width: 100%;
			}
	}

/* Mobile */

	@include breakpoint(mobile) {

		/* Basic */

			body {
				min-width: 320px;
			}

			body, input, select, textarea {
				font-size: 11pt;
			}

		/* BG */

			@include bg(_misc(bg-width) * 0.2);

		/* Header */

			#header {
				h1 {
					font-size: 2.5em;
				}

				p {
					font-size: 1em;
				}

				nav {
					font-size: 1em;

					a {
						&:hover {
							font-size: 1em;
						}

						&:active {
							font-size: 1em;
						}
					}
				}
			}

	}

/* Mobile (Portrait) */

	@include breakpoint(mobilep) {

		/* BG */

			@include bg(_misc(bg-width) * 0.275);

		/* Header */

			#header {
				nav {
					padding: 0 1em;
				}
			}

	}
